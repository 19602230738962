import React from 'react';
import Toolbar from './Toolbar';
import ContactSection from './ContactSection';
import VideoSection from './VideoSection';

const Template = () => {
    return (

        <div>
            {/* Sticky Toolbar */}
            <div className="sticky top-0 z-50 bg-white shadow">
                <Toolbar />
            </div>

            <section id="accueil" className="bg-gray-100 py-10 md:py-20">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12 items-center">
                        <div className="md:col-span-1 flex flex-col justify-center">
                            <h1 className="text-4xl font-bold mb-4 text-gray-800">
                                PIGMA SYSTEM
                            </h1>
                            <p className="text-lg mb-8 text-gray-600">
                                Bienvenue dans l'univers innovant de PIGMA SYSTEM, une révolution dans le monde de la colombophilie qui associe tradition et technologies de pointe. Notre mission est de rendre les compétitions de pigeons voyageurs plus accessibles, sécurisées et équitables grâce à notre système de suivi RFID avancé.
                            </p>
                            {/* Button "Explore It" removed */}
                        </div>
                        <div className="md:col-span-2 flex justify-center items-center">
                            <img
                                src="pigma_1.png" // Replace with your image path
                                alt="Pigeon Image"
                                className="max-w-full h-auto rounded-lg shadow-lg" // Adjust the width as necessary
                            />
                        </div>
                    </div>
                </div>
            </section>




            {/* Produits Section */}
            <section id="produits" className="bg-white py-10 md:py-20">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold mb-8 text-gray-800">PRODUITS</h2>
                    <div className="flex justify-center flex-wrap">
                        <div className="flex flex-col items-center mx-4 mb-8">
                            <img
                                src="one_field.png"
                                alt="Produit 1"
                                className="w-48 h-48 object-cover rounded-lg shadow-md mb-4"
                            />
                            <p className="text-gray-600 font-semibold">Constateur 1 entrée</p>
                        </div>
                        <div className="flex flex-col items-center mx-4 mb-8">
                            <img
                                src="one_field.png"
                                alt="Produit 2"
                                className="w-48 h-48 object-cover rounded-lg shadow-md mb-4"
                            />
                            <p className="text-gray-600 font-semibold">Master associations</p>
                        </div>
                        <div className="flex flex-col items-center mx-4 mb-8">
                            <img
                                src="master.png"
                                alt="Produit 3"
                                className="w-48 h-48 object-cover rounded-lg shadow-md mb-4"
                            />
                            <p className="text-gray-600 font-semibold">Constateur 2 entrées</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* A propos Section */}
            <section id="apropos" className="bg-gradient-to-r from-gray-100 via-white to-gray-100 py-10 md:py-20">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-4xl font-bold mb-6 text-center text-gray-800">Découvrez PIGMA SYSTEM</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                        <div className="order-2 md:order-1">
                            <h3 className="text-3xl font-semibold text-gray-700 mb-4">L'Essence de la Colombophilie Renouvelée</h3>
                            <p className="text-lg text-gray-600 mb-4">
                                La colombophilie, un sport ancestral enrichi par des millénaires d'histoire, se transforme avec PIGMA SYSTEM. Notre technologie avant-gardiste de suivi RFID fusionne tradition et innovation pour offrir un sport plus équitable et accessible.
                            </p>
                            <h3 className="text-3xl font-semibold text-gray-700 mb-4">Notre Mission</h3>
                            <p className="text-lg text-gray-600 mb-4">
                                Nous nous engageons à moderniser la colombophilie, en utilisant des technologies de pointe pour améliorer la sécurité des compétitions, faciliter leur gestion et dynamiser l'expérience des participants.
                            </p>
                        </div>
                        <div className="order-1 md:order-2 flex justify-center items-center mb-8 md:mb-0">
                            <img
                                src="team.png" // Replace with the path to your team photo
                                alt="Notre Équipe"
                                className="w-full h-auto rounded-lg shadow-xl"
                            />
                        </div>
                    </div>
                    <div className="text-center mt-12">
                        <h3 className="text-3xl font-semibold text-gray-700 mb-4">Rencontrez l'Équipe</h3>
                        <p className="text-lg text-gray-600 mb-4">
                            Derrière chaque grande innovation se trouve une équipe exceptionnelle. Chez PIGMA SYSTEM, nos fondateurs et employés partagent une passion commune pour la colombophilie et l'innovation technologique.
                        </p>
                        <div className="flex justify-center space-x-4 mt-4">
                            <div>
                                <img src="achmi.png" alt="Achmi Gnay Diby, CEO" className="w-24 h-24 rounded-full shadow-lg" />
                                <button className="mt-2 text-sm font-semibold text-gray-700">Achmi Gnay Diby<br />CEO</button>
                            </div>
                            <div>
                                <img src="hannat.png" alt="Mohamed Hannat, CTO" className="w-24 h-24 rounded-full shadow-lg" />
                                <button className="mt-2 text-sm font-semibold text-gray-700">Mohamed Hannat<br />CTO</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="nouvelle-ere" className="py-10 md:py-20 bg-gradient-to-r from-blue-100 via-blue-200 to-blue-100">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center mb-10">
                        <h2 className="text-4xl font-extrabold text-gray-800 mb-6">
                            L'Aube d'une Nouvelle Ère
                        </h2>
                        <p className="text-md text-gray-600">
                            Unissant la tradition séculaire à la technologie de pointe, PIGMA SYSTEM annonce un nouveau chapitre dans les courses de pigeons.
                        </p>
                    </div>
                    <div className="flex flex-wrap items-center justify-center">
                        <div className="w-full lg:w-1/2 px-4 mb-10 lg:mb-0">
                            <div className="relative rounded-lg overflow-hidden">
                                <div className="absolute inset-0 bg-black bg-opacity-25"></div>
                                <img
                                    src="pigeon_start.gif" // Remplacer par une image appropriée
                                    alt="Début de la course de pigeons"
                                    className="w-full h-full object-cover object-center"
                                />
                                <div className="absolute inset-0 p-6 flex flex-col justify-end">
                                    <span className="text-xl font-bold text-white">
                                        L'effervescence du départ de la course, maintenant amplifiée par la technologie.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 px-4">
                            <div className="bg-white p-6 rounded-lg shadow-xl">
                                <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                                    Innovation Intuitive
                                </h3>
                                <p className="text-md text-gray-600 mb-4">
                                    Notre technologie RFID pionnière préserve l'authenticité de la course, offrant des aperçus en temps réel et des capacités de gestion sans précédent aux passionnés du monde entier.
                                </p>
                                <a href="#features" className="inline-block bg-blue-500 text-white px-6 py-2 rounded-md font-semibold mt-4 transition duration-300 ease-in-out hover:bg-blue-600">
                                    Découvrir Plus
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="data-sync-analysis" className="py-10 md:py-20 bg-white">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-10">
                        <h2 className="text-4xl font-bold text-gray-800 mb-4">
                            Synchronisation et Analyse des Données
                        </h2>
                        <p className="text-md mb-6 text-gray-600 mx-auto" style={{ maxWidth: '800px' }}>
                            Une interface transparente entre l'innovation technologique et la passion de la colombophilie, où précision et accessibilité s'entrelacent pour révolutionner le suivi des compétitions.
                        </p>
                    </div>
                    <div className="flex flex-wrap justify-center items-center text-center gap-10">
                        <div className="w-full lg:w-1/2 px-4 mb-6">
                            <div className="shadow-lg rounded-lg">
                                <div className="p-6 bg-blue-100">
                                    <h3 className="text-2xl font-bold text-gray-800 mb-4">
                                        Visualisation Intuitive
                                    </h3>
                                    <p className="text-md text-gray-600">
                                        Les performances des pigeons sont affichées sur une interface claire et détaillée, offrant des analyses en temps réel pour chaque course.
                                    </p>
                                </div>
                                <img
                                    src="server.png" // Replace with the actual path to the screenshot image
                                    alt="Interface du serveur PIGMA"
                                    className="w-full object-cover rounded-b-lg"
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-center items-center text-center gap-10">
                            <div className="shadow-lg rounded-lg bg-gray-100 text-gray-600 mx-auto p-20 flex-1">
                                <h3 className="text-4xl font-bold mb-8">
                                    Transmission Instantanée
                                </h3>
                                <p className="text-md mb-8">
                                    Les données de la course sont envoyées directement depuis le constateur vers notre plateforme en un instant, garantissant l'actualité et la précision des résultats.
                                </p>
                                <p className="text-mb-8">
                                    L'application mobile assure une communication efficace avec le constateur via Bluetooth pour la synchronisation et permet l'envoi des informations par WiFi vers le serveur. Elle intègre également des fonctionnalités de gestion approfondie, telles que la suppression de pigeons, le contrôle de l'horloge du constateur et l'accès aux informations des utilisateurs, consolidant ainsi la gestion des compétitions aviaires.
                                </p>
                            </div>
                            <div className="flex-1">
                                <img
                                    src="phone.png" // Replace with the actual path to the image of the app
                                    alt="Application PIGMA"
                                    className="w-1/2 lg:w-2/5 xl:w-1/3 mx-auto object-cover rounded-lg shadow-lg" // Adjust size as needed
                                />
                            </div>
                        </div>


                    </div>
                </div>
            </section>



<VideoSection/>



            {/* Contact Section */}
            <section id="contact" className="bg-gray-100 py-10 md:py-20">

                <ContactSection />

            </section>

            {/* Inscription Section */}
            <section id="inscription" className="bg-gray-100 py-20">
                {/* Add your Inscription content here */}
            </section>
        </div>
    );
};

export default Template;