

import React, { useState } from 'react';

const Toolbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scrollToSection = (sectionId) => {
    setIsMenuOpen(false); // Close mobile menu when a section is selected
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className="bg-cool-gray-200 py-4 shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          {/* Logo */}
          <img 
            src="logo.png"  // Ensure the path to your logo image is correct
            alt="PIGMA SYSTEM Logo" 
            className="h-10"
          />

          {/* Mobile menu button */}
          <div className="lg:hidden">
            <button
              className="text-gray-800 hover:text-gray-600 focus:outline-none focus:text-gray-600"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                {isMenuOpen ? (
                  // Icon for Close (X)
                  <path fillRule="evenodd" clipRule="evenodd" d="M18.707 5.293a1 1 0 00-1.414 0L12 10.586 6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 000-1.414z" />
                ) : (
                  // Icon for Menu (Hamburger)
                  <path fillRule="evenodd" clipRule="evenodd" d="M4 5h16a1 1 0 100-2H4a1 1 0 100 2zm16 7H4a1 1 0 100 2h16a1 1 0 100-2zm0 9H4a1 1 0 100 2h16a1 1 0 100-2z" />
                )}
              </svg>
            </button>
          </div>

          {/* Links for large screens */}
          <div className="hidden lg:flex space-x-4">
          <button
              className="text-gray-800 hover:bg-gray-300 py-2 px-4 rounded"
              onClick={() => scrollToSection('accueil')}
            >
              Accueil
            </button>
            <button
              className="text-gray-800 hover:bg-gray-300 py-2 px-4 rounded"
              onClick={() => scrollToSection('produits')}
            >
              Produits
            </button>
            <button
              className="text-gray-800 hover:bg-gray-300 py-2 px-4 rounded"
              onClick={() => scrollToSection('apropos')} 
            >
              A propos
            </button>
            <button
              className="text-gray-800 hover:bg-gray-300 py-2 px-4 rounded"
              onClick={() => scrollToSection('nouvelle-ere')} 
            >
            Départ Technologique
            </button>
            <button
              className="text-gray-800 hover:bg-gray-300 py-2 px-4 rounded"
              onClick={() => scrollToSection('data-sync-analysis')} 
            >
            Resultat Online
            </button>
            <button
              className="text-gray-800 hover:bg-gray-300 py-2 px-4 rounded"
              onClick={() => scrollToSection('contact')}
            >
              Contact
            </button>          </div>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="lg:hidden flex flex-col space-y-2 mt-2">
          <button
              className="text-gray-800 hover:bg-gray-300 py-2 px-4 rounded"
              onClick={() => scrollToSection('accueil')}
            >
              Accueil
            </button>
            <button
              className="text-gray-800 hover:bg-gray-300 py-2 px-4 rounded"
              onClick={() => scrollToSection('produits')}
            >
              Produits
            </button>
            <button
              className="text-gray-800 hover:bg-gray-300 py-2 px-4 rounded"
              onClick={() => scrollToSection('apropos')} 
            >
              A propos
            </button>
            <button
              className="text-gray-800 hover:bg-gray-300 py-2 px-4 rounded"
              onClick={() => scrollToSection('nouvelle-ere')} 
            >
            Départ Technologique
            </button>
            <button
              className="text-gray-800 hover:bg-gray-300 py-2 px-4 rounded"
              onClick={() => scrollToSection('data-sync-analysis')} 
            >
            Resultat Online
            </button>
            <button
              className="text-gray-800 hover:bg-gray-300 py-2 px-4 rounded"
              onClick={() => scrollToSection('contact')}
            >
              Contact
            </button>          </div>
        )}
      </div>
    </nav>
  );
};

export default Toolbar;
