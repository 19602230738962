import React from 'react';

const VideoSection = () => {
  return (
    <section id="video-explanation" className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap items-center justify-center">
          <div className="lg:w-1/2 px-4 mb-8 lg:mb-0">
            <h2 className="text-4xl font-bold text-center text-gray-800 mb-6">
            Explorez Notre Projet
</h2>
            <p className="text-lg text-gray-600 text-center mb-8">
            Plongez dans l'univers du système PIGMA avec notre vidéo détaillée expliquant le projet. Découvrez comment nous intégrons des technologies de pointe à la colombophilie traditionnelle pour améliorer et innover dans le sport.            </p>
            <video
              controls
              className="w-full h-auto rounded-lg shadow-lg"
              poster="presentation.mp4" // Replace with the path to your video thumbnail
            >
              <source src="presentation.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
