import logo from './logo.svg';
import './App.css';
import Template  from './Template';

function App() {
  return <Template />;

}

export default App;
