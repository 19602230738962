import React, { useRef ,useState} from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import emailjs from 'emailjs-com';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import '@fortawesome/fontawesome-free/css/all.min.css';

// Correcting the path for the marker icon images
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const redirectToGoogleMaps = () => {
    window.open("https://www.google.com/maps/place/Technopark+Rabat/@34.0197875,-6.8344727,15z/data=!4m6!3m5!1s0xda76b88101ea575:0xf106a77ae1980e65!8m2!3d34.0197875!4d-6.8344727!16s%2Fg%2F1q67sfv9k?entry=ttu", "_blank");
};

const MapComponent = () => {
    return (
        <MapContainer center={[34.0197875, -6.8344727]} zoom={13} scrollWheelZoom={false} className="h-full w-full">
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={[34.0197875, -6.8344727]} eventHandlers={{ click: redirectToGoogleMaps }}>
                <Popup>PIGMA SYSTEM</Popup>
            </Marker>
        </MapContainer>
    );
};

const ContactSection = () => {
    const form = useRef();
    const [submitted, setSubmitted] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.init("nauOdkpthopb-RiA7"); // Initialize EmailJS with your public key
        emailjs.sendForm('service_7tq4d1p', 'template_4kvqjbk', form.current) // Use your service ID and the correct template ID
            .then((result) => {
                console.log("Email successfully sent!", result.text);
                setSubmitted(true); // Update the state to show the success message
                setTimeout(() => setSubmitted(false), 5000); // Hide the message after 5 seconds
                form.current.reset(); // Reset the form fields
            }, (error) => {
                console.error("Failed to send the message:", error.text);
                alert('Failed to send the message, please try again.');
            });
    };


   
    return (
        <section id="contact" className="bg-white py-20">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-3xl font-bold mb-4 text-gray-800">Contactez-nous</h2>
                <p className="mb-8 text-gray-600">
                                 </p>
                <form ref={form} onSubmit={sendEmail} className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {submitted && (
                        <div className="col-span-3 p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
                            <span className="font-medium">Success!</span> Your message has been sent.
                        </div>
                    )}
                    <div>
                        <div className="mb-4">
                            <label htmlFor="name" className="block font-bold mb-2 text-gray-700">Nom et prénom</label>
                            <input type="text" name="name" id="name" className="border border-gray-400 p-3 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent" placeholder="Hindley Earnshaw"/>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className="block font-bold mb-2 text-gray-700">Email</label>
                            <input type="email" name="email" id="email" className="border border-gray-400 p-3 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent" placeholder="example@example.com"/>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="message" className="block font-bold mb-2 text-gray-700">Message</label>
                            <textarea name="message" id="message" className="border border-gray-400 p-3 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent" rows="4" placeholder="Hi there"></textarea>
                        </div>
                        <button type="submit" className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 flex items-center">
                            Envoyer <span className="ml-2">→</span>
                        </button>
                    </div>
                    <div className="flex flex-col">
                        <p className="mb-4 text-gray-600">
                            <span className="font-bold">+212 645615569</span>
                        </p>
                        <p className="text-gray-600">
                        10 Rue Ghandi, Rabat 10000, Maroc
                        </p>
                    </div>
                    
                    <div className="h-64 rounded-lg overflow-hidden">
                        <MapComponent />
                    </div>
                 {/* Social Media Icons */}
            <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 pb-4">
                <div className="flex flex-row space-x-4 justify-center">
                    <a href="https://www.facebook.com/search/top?q=pigma" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook-f fa-2x"></i>
                    </a>
                    <a href="https://www.youtube.com/@pigma6139" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-youtube fa-2x"></i>
                    </a>
                </div>
            </div>
                </form>
            </div>
        </section>
    );
};

export default ContactSection;